.card {
    border: 1px solid black;
    background-color: #d6e4ed;
    box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    margin: 20px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .front {
    width: 100%;
    height: 300px;
    border-bottom: 2px solid #a4bac7;
    object-fit: cover;
  }
  .title {
    text-align: center;
    padding-bottom: 10px;
    margin: 20px 10px 10px 10px;
    color: #243c58;
  }
  .p {
    margin: 5px 15px;
    text-align: justify;
    font-family: 'Lora', serif;
    color: black;
    font-size: 1.1rem;
  }
  .views {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .views p {
    font-size: 0.8rem;
    color: #5f587a;
  }

  .button {
    cursor: pointer;
    background-color: #5f6c96;
    border: none;
    border-top: 2px solid black;
    color: black;
    font-family: Arial, sans-serif;
    height: 45px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 45px;
    text-decoration: none;
    display: block;
  }
  .button:active {
    background-color: #243c58;
    border-top: 2px solid #5f6c96;
    color: #a4bac7;
    transform: scale(1.05);
  }
  .icon {
    width: 25px;
    height: 25px;
    margin: 0 5px;
    color: #5f587a;
  }