/* header.module.css */

.header {
    display: flex;
    justify-content: center;
    background-color: #5f6c96;
  }
  
  .navItem, .selected {
    display: flex;
    align-items: center;
    margin: 20px;
    cursor: pointer;
  }
  
  .navItem p, .selected p {
    margin-left: 8px;
    font-size: 1rem;
    color: white;
    font-weight: bold;
  }
  
  .icon {
    color: white;
    font-size: 1.2rem;
  }
  
  .selected {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 8px;
  }
  