@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


body{
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
*{
    box-sizing: border-box;
}
a{
    color: inherit;
    text-decoration: inherit;
}